import { template as template_596dd0bc26534397a6965b5c2f185310 } from "@ember/template-compiler";
const WelcomeHeader = template_596dd0bc26534397a6965b5c2f185310(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
