import { template as template_448e077e666c4ec99ca88190d4e153bf } from "@ember/template-compiler";
const SidebarSectionMessage = template_448e077e666c4ec99ca88190d4e153bf(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
