import { template as template_10b90ab04bc24f4c8bed2d8691e24721 } from "@ember/template-compiler";
const FKLabel = template_10b90ab04bc24f4c8bed2d8691e24721(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
